<template>
  <div id="settings-team-name">
    <b-card footer-tag="footer">

      <rc-overlay :show="loading">
        <b-card-text>
          <validation-observer ref="editTeamNameValidation">
            <form
              id="editTeamNameForm"
              @submit.prevent
            >

              <b-row>
                <b-col
                  cols="12"
                  class="pb-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('conf.settings.team-name.form.name')"
                    rules="required|min:3|max:10"
                  >
                    <label for="team-name">
                      {{ $t('conf.settings.team-name.form.name') }}
                    </label>
                    <b-form-input
                      id="team-name"
                      v-model="teamName.name"
                      type="text"
                      minlength="3"
                      maxlength="10"
                      :placeholder="$t('conf.settings.team-name.form.name')"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  class="pb-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('conf.settings.team-name.form.phone')"
                    rules="digits:9"
                  >
                    <label for="phoneNumber">
                      {{ $t('conf.settings.team-name.form.phone') }}
                    </label>
                    <b-form-input
                      id="phoneNumber"
                      v-model="teamName.phoneNumber"
                      type="number"
                      minlength="9"
                      maxlength="9"
                      :placeholder="$t('conf.settings.team-name.form.phone')"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

            </form>
          </validation-observer>
        </b-card-text>
      </rc-overlay>

      <template #footer>

        <div class="text-right">
          <b-button
            class="mr-1"
            variant="outline-warning"
            @click="$router.back()"
          >
            {{ $t('shared.button.cancel') }}
          </b-button>

          <b-button
            variant="primary"
            :disabled="loading"
            @click="submitForm"
          >
            {{ $t('shared.button.submit') }}
          </b-button>
        </div>

      </template>

    </b-card>
  </div>
</template>

<script>
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import {
  BButton, BCard, BCardText, BCol, BFormInput, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BCard,
    BCardText,
    BButton,
    RcOverlay,
    BRow,
    BCol,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    teamName() {
      return cloneNested(this.$store.getters['settings/getTeamNameData'])
    },
  },
  created() {
    this.initTeamNameData()
  },
  beforeDestroy() {
    this.$store.commit('settings/CLEAR_TEAM_NAME_DATA')
  },
  methods: {
    initTeamNameData() {
      this.loading = true
      this.$store.dispatch('settings/fetchTeamNameData', this.$route.params.id)
        .then(() => {
          this.loading = false
        })
        .catch(err => parseRequestError(err))
    },
    submitForm() {
      this.$refs.editTeamNameValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }

          this.loading = true
          const teamNameData = cloneNested(this.teamName)

          const payload = {
            id: teamNameData.id,
            data: teamNameData,
          }
          this.$store.dispatch('settings/updateTeamName', payload)
            .then(() => {
              window.toast.notify.success(this.$t('conf.settings.team-name.notify.edit.success_title'))
            })
            .catch(err => {
              parseRequestError(err)
              this.initTeamNameData()
            })
            .finally(() => {
              this.loading = false
            })

          return true
        })
    },
  },
}
</script>
